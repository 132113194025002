import { Component, createRef } from 'react'

import DateRange from '../components/DateRange'
import SkrSingleDropdownList from '../components/SkrSingleDropdownList'
import SkrSingleDropdownRange from '../components/SkrSingleDropdownRange'
import SkrToggleButton from '../components/SkrToggleButton'
import SkrContactList from '../components/SkrContactList'
import SkrResultList from '../components/SkrResultList'
import SkrFeedbackShortList from '../components/SkrFeedbackShortList'
import SkrDataController from '../components/SkrDataController'
import Markup from '../forked/markup';
import initReactivesearch from '../server';
import SkrSearchFunnel from '../components/SkrSearchFunnel'
import SkrReactiveBase from '../components/SkrReactiveBase'
import { renderSidebar } from '../components/sidebar'
import { getComponentConfig } from '../components/config'
import { getSettings } from '../common/settings'

const defaultState = {result_type: ''}

export default class App extends Component {

  static concatRequestBody(request) {
    return new Promise(resolve => {
      let body = [];
      request.on('data', (chunk) => {
        body.push(chunk);
      }).on('end', () => {
        body = Buffer.concat(body).toString();
        resolve(body);
      });
    });
  }

  static async getInitialProps(serverArguments) {
    let { query, req } = serverArguments

    Object.keys(query).map(function(key, index) {
      try {
        query[key] = JSON.parse(query[key]);
      } catch (e) {}
    })

    if (query['below_results_url']) {
      const belowResultsUrl = query['below_results_url']
      delete query['below_results_url']
      query['below_results_content'] = '<esi:include src="' + belowResultsUrl + '"/>'
    }

    const initialState = {
      ...defaultState,
      ...query
    }

    const reqBody = await App.concatRequestBody(req)
    if (reqBody) {
      initialState['below_results_content'] = reqBody
    }

    const components = getComponentConfig(initialState, 'landingpage')

    return {
      initialState: initialState,
      store: await initReactivesearch([
        {
          ...components.continents, type: 'SkrSingleDropdownList', source: SkrSingleDropdownList,
        }, {
          ...components.countries, type: 'SkrSingleDropdownList', source: SkrSingleDropdownList,
        }, {
          ...components.regions, type: 'SkrSingleDropdownList', source: SkrSingleDropdownList,
        }, {
          ...components.trip_type, type: 'SkrSingleDropdownList', source: SkrSingleDropdownList,
        }, {
          ...components.date_range, type: 'DateRange', source: DateRange,
        }, {
          ...components.duration, type: 'SkrSingleDropdownRange', source: SkrSingleDropdownRange,
        }, {
          ...components.availability, type: 'SkrSingleDropdownRange', source: SkrSingleDropdownRange,
        }, {
          ...components.result_type, type: 'SkrToggleButton', source: SkrToggleButton,
        }, {
          ...components.custom_sorting, type: 'SkrDataController', source: SkrDataController,
        }, {
          ...components.flags, type: 'SkrDataController', source: SkrDataController,
        }, {
          ...components.results, type: 'SkrResultList', source: SkrResultList,
        }, {
          ...components.feedbacks, type: 'SkrFeedbackShortList', source: SkrFeedbackShortList,
        }, {
          ...components.contact, type: 'SkrContactList', source: SkrContactList,
        }, {
          ...components.search_funnel, type: 'SkrSearchFunnel', source: SkrSearchFunnel,
        }], initialState, getSettings()),
    }
  }

  handleResultTypeChange (result_type) {
    if (!result_type[0]) return
    this.setState({
      result_type: result_type[0].value,
    })
  }

  toggleSearchFacetsAttribute = () => {
    if (this.searchFacetsRef.current) {
      const searchFacets = this.searchFacetsRef.current;
      const isOpen = searchFacets.hasAttribute('data-is-open');

      // Toggle the attribute
      if (isOpen) {
        searchFacets.removeAttribute('data-is-open');
      } else {
        searchFacets.setAttribute('data-is-open', '');
      }
    }
  };

  constructor(props) {
    const { initialState } = props;
    super(props);
    this.state = initialState;

    this.searchFacetsRef = createRef();
  }

  render () {
    const components = getComponentConfig(this.state, 'landingpage')

    return (<SkrReactiveBase {...getSettings()} initialState={this.props.store}>
      <div className="panel">
        <div className="grid search-facets" ref={this.searchFacetsRef} is-collapsible-on-mobile>
          <SkrSingleDropdownList
              className="search-facet grid__column is-small-12 is-medium-4" {...components.continents}  />
          <SkrSingleDropdownList
              className="search-facet grid__column is-small-6 is-medium-4" {...components.countries}  />
          <SkrSingleDropdownList
              className="search-facet grid__column is-small-6 is-medium-4" {...components.regions}  />
          <DateRange className="search-facet grid__column is-small-12 is-medium-3" {...components.date_range} />
          <SkrSingleDropdownRange
              className="search-facet grid__column is-small-6 is-medium-3" {...components.duration} />
          <SkrSingleDropdownRange
              className="search-facet grid__column is-small-6 is-medium-3" {...components.availability} />
          <SkrToggleButton className="search-facet result-type grid__column is-medium-3" {...components.result_type}
                           onValueChange={this.handleResultTypeChange.bind(this)}/>
          <SkrDataController {...components.flags} />
          <SkrDataController {...components.custom_sorting} />
        </div>
        <button className="search-facets-toggle" onClick={this.toggleSearchFacetsAttribute}>
          <span className="search-facets-toggle__text search-facets-toggle__text--show">Filter anzeigen</span>
          <span className="search-facets-toggle__text search-facets-toggle__text--hide">Filter ausblenden</span>
        </button>
      </div>
      <div className="grid">
        <div className={'grid__column is-small-12' + (this.state.result_type !== 'departure' ? ' is-medium-8' : '')}>
          <SkrResultList {...components.results} />
          {this.renderBelowResultsContent()}
        </div>
        <div className="grid__column is-small-12 is-medium-4 hide-on-small" data-stick-in-parent>
          {renderSidebar(components, this.state.result_type, this.props.store.hits.feedbacks.total)}
        </div>
      </div>
      <SkrSearchFunnel components={components} {...components.search_funnel} />
    </SkrReactiveBase>)
  }

  renderBelowResultsContent() {
    if (this.state.result_type === 'departure' || !this.state.below_results_content) {
      return;
    }
    const parser = typeof document==='undefined' && new (require('dom-parser'))
    return (<Markup parser={parser} trim={false} markup={this.state.below_results_content} type="html"></Markup>)
  }

  componentDidUpdate() {
    if (typeof $ !== "undefined" && typeof document !== "undefined") {
      $(document).trigger('searchChanged')
    }
  }
}

if (global.__optimizeReactComponentTree) {
  __optimizeReactComponentTree(App)
}
